import { createAction, props } from '@ngrx/store';
import { VulcanKeys } from '@prc-lib-core';
import { AnswersState } from '../reducers/answers.reducer';

export enum ActionTypes {
  Init = '[Answers] Init',
  Update = '[Answers] Update',
  Confirmation = '[Answers] Confirmation',
  UpdateOne = '[Answers] Update One',
}

export interface SelectedAnswer {
  question?: VulcanKeys;
  answer?: string;
}

export const AnswersInitAction = createAction(ActionTypes.Init);

export const AnswersUpdateAction = createAction(ActionTypes.Update, props<{ payload: AnswersState }>());

export const AnswsersUpdateSomeAction = createAction(ActionTypes.UpdateOne, props<{ payload: { [key in keyof AnswersState]?: unknown } }>());
